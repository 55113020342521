import { useEffect } from 'react';
import './Ressala.css';
import { sounds } from '../../assets/sounds';

const Ressala = () => {
  useEffect(() => {
    const audio = new Audio(sounds.catSing);
    audio.play();
  }, []);

  return (
    <div className="ressala">
      <div className='text'>
      <h1>Happy Birthday, Amina !!</h1>
      <span>
      This is a special gift for someone as inspiring as you.
Your kindness and intelligence light up every room, and we admire the amazing person you are.
Keep believing in yourself and chasing your dreams. You have the potential to achieve incredible things, and we are so proud of you.
This simple thing was created with love by my sister Chiraz and Malak, and me. We hope it shows how much you mean to us.
Wishing you a wonderful birthday and a bright future! 
      </span>
      </div>
      <div className='signature'>
      <span>With love,</span>
      <span>Rania, Malak, Esslam.</span>
      </div>
    </div>
  );
};

export default Ressala;
