import React, { useState } from 'react';
import { motion } from 'framer-motion';
import cassetteImage from '../../assets/cassette.png';
import { sounds } from '../../assets/sounds';
import './CassetteDeck.css';

const CassetteDeck = ({ onCassetteInserted }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isInserted, setIsInserted] = useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event, info) => {
    setIsDragging(false);
    const target = event.target.getBoundingClientRect();
    const slot = document.querySelector('.cassette-slot').getBoundingClientRect();

    // Check if cassette is dropped in the slot
    if (
      target.top < slot.bottom &&
      target.bottom > slot.top &&
      target.left < slot.right &&
      target.right > slot.left
    ) {
      setIsInserted(true);
      // Play cassette insertion sound
      const audio = new Audio(sounds.cassette);
      audio.play();
      // Create sparkles
      for (let i = 0; i < 6; i++) {
        createSparkle(
          slot.left + Math.random() * slot.width,
          slot.top + Math.random() * slot.height
        );
      }
      setTimeout(() => {
        onCassetteInserted();
      }, 500);
    }
  };

  const createSparkle = (x, y) => {
    const sparkle = document.createElement('div');
    sparkle.className = 'sparkle';
    sparkle.innerHTML = '✨';
    sparkle.style.left = `${x}px`;
    sparkle.style.top = `${y}px`;
    document.body.appendChild(sparkle);
    
    sparkle.addEventListener('animationend', () => {
      sparkle.remove();
    });
  };

  return (
    <div className="deck-container">
      <motion.div
        className="cassette-deck"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        <div className={`cassette-slot ${isDragging ? 'drag-over' : ''} ${isInserted ? 'inserted' : ''}`}>
          {!isInserted && 'Insert Cassette'}
        </div>
      </motion.div>
      
      {!isInserted && (
        <motion.div
          className={`draggable-cassette ${isDragging ? 'dragging' : ''}`}
          style={{
            backgroundImage: `url(${cassetteImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '250px',
            height: '160px',
            cursor: isDragging ? 'grabbing' : 'grab'
          }}
          drag
          dragSnapToOrigin={!isInserted}
          dragElastic={0.5}
          dragTransition={{ bounceStiffness: 300, bounceDamping: 20 }}
          whileDrag={{ 
            scale: 1.1,
            rotate: 5,
            boxShadow: '0 5px 15px rgba(255, 105, 180, 0.4)'
          }}
          whileHover={{ scale: 1.05 }}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        />
      )}
    </div>
  );
};

export default CassetteDeck;
