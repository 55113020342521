import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CassetteDeck from './components/CassetteDeck/CassetteDeck';
import Ressala from './components/Ressala/Ressala';
import './App.css';
import { sounds } from './assets/sounds';

function App() {
  const [countdown, setCountdown] = useState(3);
  const [showButton, setShowButton] = useState(false);
  const [showCassetteDeck, setShowCassetteDeck] = useState(false);
  const [showRessala, setShowRessala] = useState(false);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setTimeout(() => {
        setShowButton(true);
      }, 1000);
    }
  }, [countdown]);

  const handleCatClick = () => {
    setShowButton(false);
    setShowCassetteDeck(true);
  };

  const handleCassetteInserted = () => {
    setTimeout(() => {
      setShowCassetteDeck(false);
      setShowRessala(true);
    }, 1000);
  };

  const handleMeowClick = () => {
    const audio = new Audio(sounds.meow);
    audio.play();
    handleCatClick();
  };

  return (
    <div className="app-wrapper">
      <div className="heart-background">
        {[...Array(12)].map((_, index) => (
          <div key={index} className="heart" />
        ))}
      </div>
      <div className="app-container">
        <motion.div
          className="heart-border"
          initial={{ opacity: 0, scale: 1.2 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        />

        {/* Countdown Animation */}
        <AnimatePresence mode="wait">
          {countdown > 0 && (
            <motion.div
              className="countdown-number"
              key="countdown"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.5 }}
              transition={{ duration: 0.5 }}
            >
              {countdown}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Button Animation */}
        <AnimatePresence mode="wait">
          {showButton && (
            <motion.button
              className="cat-button"
              key="button"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleMeowClick}
            >
              🐱 Meow!
            </motion.button>
          )}
        </AnimatePresence>

        {/* CassetteDeck Animation */}
        <AnimatePresence mode="wait">
          {showCassetteDeck && (
            <motion.div
              key="cassette"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
            >
              <CassetteDeck onCassetteInserted={handleCassetteInserted} />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Ressala Animation */}
        <AnimatePresence mode="wait">
          {showRessala && (
            <motion.div
              key="ressala"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <Ressala isVisible={showRessala} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
